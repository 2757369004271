@charset "UTF-8";
:root {
  --sd-primary: #b56757;
  --sd-secondary: #5f5f5f;
  --sd-highlight: #3e4758;
  --sd-mark: #d7ccb7;
  --sd-neutral: #f5f4ef;
  --sd-primary-slate: #f7f2eb;
  --sd-primary-hover: #cc9983;
  --sd-primary-shadow: rgba(204, 153, 131, 0.3);
  --sd-standard: #0081c9;
  --sd-standard-hover: #00669e;
  --sd-standard-shadow: rgb(0, 102, 158, 0.3);
  --sd-danger: #df0143;
  --sd-danger-hover: #b30000;
  --sd-danger-shadow: rgb(179, 0, 0, 0.3);
  --sd-success: #47a992;
  --sd-success-hover: #57c5b6;
  --sd-success-shadow: rgba(87, 197, 182, 0.3);
  --sd-success-opacity: rgba(87, 197, 182, 0.25);
  --sd-info: #ff7b54;
  --sd-info-hover: #fc501d;
  --sd-info-shadow: rgba(252, 80, 29, 0.3);
  --sd-other: #37306b;
  --sd-other-hover: #66347f;
  --sd-other-shadow: rgb(102, 52, 127, 0.3);
  --sd-disable: #ccc; }

body.theme-merino {
  --sd-primary: #bca482;
  --sd-secondary: #a56255;
  --sd-highlight: #eae8da;
  --sd-mark: #5c6856;
  --sd-neutral: #eee8c6;
  --sd-primary-slate: #f7f6f0;
  --sd-primary-hover: #d1c1a3;
  --sd-primary-shadow: rgba(209, 193, 163, 0.3); }

body.theme-greygoose {
  --sd-primary: #4b5958;
  --sd-secondary: #62868c;
  --sd-highlight: #d7e2d5;
  --sd-mark: #859e99;
  --sd-neutral: #d6e4e4;
  --sd-primary-slate: #ebf0f0;
  --sd-primary-hover: #7d8c8b;
  --sd-primary-shadow: rgba(125, 140, 139, 0.3); }

[class*="sidebar-dark-"] {
  background-color: var(--sd-secondary); }
  [class*="sidebar-dark-"] .nav-sidebar > .nav-item > .nav-link.active {
    color: var(--sd-mark); }
  [class*="sidebar-dark-"] .nav-sidebar > .nav-item.menu-open > .nav-link,
  [class*="sidebar-dark-"] .nav-sidebar > .nav-item:hover > .nav-link,
  [class*="sidebar-dark-"] .nav-sidebar > .nav-item > .nav-link:focus {
    background-color: var(--sd-highlight);
    color: var(--sd-mark); }
  [class*="sidebar-dark-"] .nav-treeview > .nav-item > .nav-link.active,
  [class*="sidebar-dark-"] .nav-treeview > .nav-item > .nav-link.active:hover,
  [class*="sidebar-dark-"] .nav-treeview > .nav-item > .nav-link.active:focus {
    background-color: var(--sd-primary);
    color: var(--sd-mark); }
  [class*="sidebar-dark-"] .nav-treeview > .nav-item > .nav-link:hover,
  [class*="sidebar-dark-"] .nav-treeview > .nav-item > .nav-link:focus {
    background-color: var(--sd-highlight);
    color: var(--sd-mark); }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--sd-primary); }

.navbar-light {
  background-color: var(--sd-highlight); }
  .navbar-light .navbar-nav .nav-link {
    color: var(--sd-primary); }
  .navbar-light .navbar-nav .show > .nav-link,
  .navbar-light .navbar-nav .active > .nav-link,
  .navbar-light .navbar-nav .nav-link.show,
  .navbar-light .navbar-nav .nav-link.active {
    color: var(--sd-primary); }

.navbar-light .navbar-nav .nav-link:hover {
  color: var(--sd-primary-hover); }

a {
  color: var(--sd-primary); }
  a:hover {
    color: var(--sd-primary-hover); }

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--sd-primary); }

.marquee {
  background: var(--sd-mark);
  color: var(--sd-highlight); }
  .marquee a,
  .marquee .btn-link {
    color: var(--sd-highlight); }
    .marquee a:hover,
    .marquee .btn-link:hover {
      color: var(--sd-primary-hover); }

.modal-title {
  color: var(--sd-primary); }

.btn-link {
  color: var(--sd-primary); }
  .btn-link:hover {
    color: var(--sd-primary-hover); }

.icheck-primary > input:first-child:checked + input[type="hidden"] + label::before,
.icheck-primary > input:first-child:checked + label::before {
  background-color: var(--sd-primary);
  border-color: var(--sd-primary); }

.icheck-primary > input:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before,
.icheck-primary > input:first-child:not(:checked):not(:disabled):hover + label::before {
  border-color: var(--sd-primary); }

.icheck-success > input:first-child:checked + input[type="hidden"] + label::before,
.icheck-success > input:first-child:checked + label::before {
  background-color: var(--sd-success);
  border-color: var(--sd-success); }

.icheck-success > input:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before,
.icheck-success > input:first-child:not(:checked):not(:disabled):hover + label::before {
  border-color: var(--sd-success); }

.page-item.active .page-link {
  color: #fff;
  background-color: var(--sd-primary);
  border-color: var(--sd-primary);
  box-shadow: 0 5px 14px 0 var(--sd-primary-shadow) !important; }

.page-item .page-link {
  color: var(--sd-primary-hover); }

.btn-primary {
  color: #fff;
  background-color: var(--sd-primary);
  border-color: var(--sd-primary);
  box-shadow: none; }
  .btn-primary:hover {
    color: #fff;
    background-color: var(--sd-primary-hover);
    border-color: var(--sd-primary-hover);
    filter: drop-shadow(5px 5px 10px var(--sd-primary-shadow)); }
  .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: var(--sd-primary-hover);
    border-color: var(--sd-primary-hover); }

.btn-standard {
  color: #fff;
  background-color: var(--sd-standard);
  border-color: var(--sd-standard);
  box-shadow: none; }
  .btn-standard:hover {
    color: #fff;
    background-color: var(--sd-standard-hover);
    border-color: var(--sd-standard-hover);
    filter: drop-shadow(5px 5px 10px var(--sd-standard-shadow)); }
  .btn-standard:not(:disabled):not(.disabled).active, .btn-standard:not(:disabled):not(.disabled):active,
  .show > .btn-standard.dropdown-toggle {
    color: #fff;
    background-color: var(--sd-standard-hover);
    border-color: var(--sd-standard-hover); }

.btn-success {
  color: #fff;
  background-color: var(--sd-success);
  border-color: var(--sd-success);
  box-shadow: none; }
  .btn-success:hover {
    color: #fff;
    background-color: var(--sd-success-hover);
    border-color: var(--sd-success-hover);
    filter: drop-shadow(5px 5px 10px var(--sd-success-shadow)); }
  .btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: var(--sd-success-hover);
    border-color: var(--sd-success-hover); }

.btn-info {
  color: #fff;
  background-color: var(--sd-info);
  border-color: var(--sd-info);
  box-shadow: none; }
  .btn-info:hover {
    color: #fff;
    background-color: var(--sd-info-hover);
    border-color: var(--sd-info-hover);
    filter: drop-shadow(5px 5px 10px var(--sd-info-shadow)); }
  .btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: var(--sd-info-hover);
    border-color: var(--sd-info-hover); }

.btn-other {
  color: #fff;
  background-color: var(--sd-other);
  border-color: var(--sd-other);
  box-shadow: none; }
  .btn-other:hover {
    color: #fff;
    background-color: var(--sd-other-hover);
    border-color: var(--sd-other-hover);
    filter: drop-shadow(5px 5px 10px var(--sd-other-shadow)); }
  .btn-other:not(:disabled):not(.disabled).active, .btn-other:not(:disabled):not(.disabled):active,
  .show > .btn-other.dropdown-toggle {
    color: #fff;
    background-color: var(--sd-other-hover);
    border-color: var(--sd-other-hover); }

.btn-outline-primary {
  color: var(--sd-primary);
  border-color: var(--sd-primary); }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: var(--sd-primary);
    border-color: var(--sd-primary);
    filter: drop-shadow(5px 5px 10px var(--sd-primary-shadow)); }
  .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: var(--sd-primary-hover);
    border-color: var(--sd-primary-hover); }

.btn-outline-info {
  color: var(--sd-info);
  border-color: var(--sd-info); }
  .btn-outline-info:hover {
    color: #fff;
    background-color: var(--sd-info);
    border-color: var(--sd-info);
    filter: drop-shadow(5px 5px 10px var(--sd-info-shadow)); }
  .btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info:not(:disabled):not(.disabled):active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: var(--sd-info-hover);
    border-color: var(--sd-info-hover); }

.btn-outline-other {
  color: var(--sd-other);
  border-color: var(--sd-other); }
  .btn-outline-other:hover {
    color: #fff;
    background-color: var(--sd-other);
    border-color: var(--sd-other);
    filter: drop-shadow(5px 5px 10px var(--sd-other-shadow)); }
  .btn-outline-other:not(:disabled):not(.disabled).active, .btn-outline-other:not(:disabled):not(.disabled):active,
  .show > .btn-outline-other.dropdown-toggle {
    color: #fff;
    background-color: var(--sd-other-hover);
    border-color: var(--sd-other-hover); }

.text-primary {
  color: var(--sd-primary) !important; }

.text-secondary {
  color: var(--sd-secondary) !important; }

.text-highlight {
  color: var(--sd-highlight) !important; }

.text-mark {
  color: var(--sd-mark) !important; }

.text-standard {
  color: var(--sd-standard) !important; }

.text-danger {
  color: var(--sd-danger) !important; }

.text-success {
  color: var(--sd-success) !important; }

.text-info {
  color: var(--sd-info) !important; }

.text-other {
  color: var(--sd-other) !important; }

.bg-primary {
  background-color: var(--sd-primary) !important; }

.bg-secondary {
  background-color: var(--sd-secondary) !important; }

.bg-highlight {
  background-color: var(--sd-highlight) !important; }

.bg-mark {
  background-color: var(--sd-mark) !important; }

.bg-standard {
  background-color: var(--sd-standard) !important; }

.bg-danger {
  background-color: var(--sd-danger) !important; }

.bg-success {
  background-color: var(--sd-success) !important; }
  .bg-success-slate {
    background-color: var(--sd-success-opacity) !important; }

.bg-info {
  background-color: var(--sd-info) !important; }

.bg-other {
  background-color: var(--sd-other) !important; }

.badge-primary {
  background-color: var(--sd-primary) !important; }

.badge-secondary {
  background-color: var(--sd-secondary) !important; }

.badge-highlight {
  background-color: var(--sd-highlight) !important; }

.badge-mark {
  background-color: var(--sd-mark) !important; }

.badge-standard {
  background-color: var(--sd-standard) !important; }

.badge-danger {
  background-color: var(--sd-danger) !important; }

.badge-success {
  background-color: var(--sd-success) !important; }

.badge-info {
  background-color: var(--sd-info) !important; }

.badge-other {
  background-color: var(--sd-other) !important; }

.badge-disable {
  background-color: var(--sd-disable) !important; }

.border-primary {
  border-color: var(--sd-primary) !important; }

.border-secondary {
  border-color: var(--sd-secondary) !important; }

.border-highlight {
  border-color: var(--sd-highlight) !important; }

.border-mark {
  border-color: var(--sd-mark) !important; }

.border-standard {
  border-color: var(--sd-standard) !important; }

.border-danger {
  border-color: var(--sd-danger) !important; }

.border-success {
  border-color: var(--sd-success) !important; }

.border-info {
  border-color: var(--sd-info) !important; }

.border-other {
  border-color: var(--sd-other) !important; }

.border-disable {
  border-color: var(--sd-disable) !important; }

.loader {
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  width: 100%;
  transition: height .2s linear;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px); }

.spinner {
  background: transparent;
  position: absolute;
  z-index: 1;
  width: 120px;
  height: 120px;
  margin: -60px 0 0 120px;
  border: 16px solid #444941;
  border-radius: 50%;
  border-top: 16px solid #f8b133;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite; }

.can-toggle {
  position: relative; }
  .can-toggle *,
  .can-toggle *:before,
  .can-toggle *:after {
    box-sizing: border-box; }
  .can-toggle input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0; }
    .can-toggle input[type="checkbox"][disabled] ~ label {
      pointer-events: none; }
      .can-toggle input[type="checkbox"][disabled] ~ label .can-toggle__switch:before {
        opacity: 0.3; }
      .can-toggle input[type="checkbox"][disabled] ~ label .can-toggle__switch:after {
        color: #495057 !important; }
    .can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:before {
      content: attr(data-unchecked);
      left: 0; }
    .can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
      content: attr(data-checked); }
  .can-toggle label {
    user-select: none;
    position: relative;
    display: flex;
    align-items: center; }
    .can-toggle label .can-toggle__label-text {
      flex-shrink: 0;
      flex-grow: 0;
      white-space: nowrap;
      margin-right: .5rem; }
    .can-toggle label .can-toggle__switch {
      position: relative; }
      .can-toggle label .can-toggle__switch:before {
        content: attr(data-checked);
        position: absolute;
        top: 0;
        text-transform: uppercase;
        text-align: center; }
      .can-toggle label .can-toggle__switch:after {
        content: attr(data-unchecked);
        position: absolute;
        z-index: 5;
        text-transform: uppercase;
        text-align: center;
        background: white;
        transform: translate3d(0, 0, 0); }
  .can-toggle input[type="checkbox"][disabled] ~ label {
    color: rgba(119, 119, 119, 0.5); }
  .can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch,
  .can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch {
    background-color: #777; }
    .can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch:after,
    .can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
      color: #5e5e5e; }
  .can-toggle input[type="checkbox"]:hover ~ label {
    color: #6a6a6a; }
  .can-toggle input[type="checkbox"]:checked ~ label:hover {
    color: #55bc49; }
  .can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch {
    background-color: #70c767; }
    .can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
      color: #4fb743; }
  .can-toggle input[type="checkbox"]:checked:focus ~ label .can-toggle__switch, .can-toggle input[type="checkbox"]:checked:hover ~ label .can-toggle__switch {
    background-color: #5fc054; }
    .can-toggle input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {
      color: #47a43d; }
  .can-toggle label {
    flex-wrap: wrap; }
    .can-toggle label .can-toggle__label-text {
      flex-shrink: 0;
      flex-grow: 0;
      white-space: nowrap;
      margin-right: .5rem; }
    .can-toggle label .can-toggle__switch {
      transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
      background: #848484; }
      .can-toggle label .can-toggle__switch:before {
        color: rgba(255, 255, 255, 0.5); }
      .can-toggle label .can-toggle__switch:after {
        -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
        transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
        color: #777; }
  .can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch:after,
  .can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4); }
  .can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
    transform: translate3d(3rem, 0, 0); }
  .can-toggle input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4); }
  .can-toggle label {
    font-size: 1rem; }
    .can-toggle label .can-toggle__switch {
      height: 1.875rem;
      flex: 0 0 6.25rem;
      border-radius: 1.875rem; }
      .can-toggle label .can-toggle__switch:before {
        left: 3.125rem;
        font-size: 1rem;
        line-height: 1.875rem;
        width: 3.125rem;
        padding: 0 12px; }
      .can-toggle label .can-toggle__switch:after {
        top: 0.125rem;
        left: 0.125rem;
        border-radius: 0.9375rem;
        width: 3rem;
        line-height: 1.625rem;
        font-size: 1rem; }
      .can-toggle label .can-toggle__switch:hover:after {
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4); }
  .can-toggle.rebrand input[type="checkbox"][disabled] ~ label {
    color: rgba(68, 68, 68, 0.5); }
  .can-toggle.rebrand input[type="checkbox"]:focus ~ label .can-toggle__switch,
  .can-toggle.rebrand input[type="checkbox"]:hover ~ label .can-toggle__switch {
    background-color: #444; }
    .can-toggle.rebrand input[type="checkbox"]:focus ~ label .can-toggle__switch:after,
    .can-toggle.rebrand input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
      color: #2b2b2b; }
  .can-toggle.rebrand input[type="checkbox"]:hover ~ label {
    color: #373737; }
  .can-toggle.rebrand input[type="checkbox"]:checked ~ label:hover {
    color: #62b125; }
  .can-toggle.rebrand input[type="checkbox"]:checked ~ label .can-toggle__switch {
    background-color: #75d32d; }
    .can-toggle.rebrand input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
      color: #5da924; }
  .can-toggle.rebrand input[type="checkbox"]:checked:focus ~ label .can-toggle__switch, .can-toggle.rebrand input[type="checkbox"]:checked:hover ~ label .can-toggle__switch {
    background-color: #69be28; }
    .can-toggle.rebrand input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle.rebrand input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {
      color: #52941f; }
  .can-toggle.rebrand label {
    flex-wrap: wrap; }
    .can-toggle.rebrand label .can-toggle__label-text {
      flex-shrink: 0;
      flex-grow: 0;
      white-space: nowrap;
      margin-right: .5rem; }
    .can-toggle.rebrand label .can-toggle__switch {
      transition: background-color 0.3s cubic-bezier(0.86, 0, 0.07, 1);
      background: #515151; }
      .can-toggle.rebrand label .can-toggle__switch:before {
        color: rgba(255, 255, 255, 0.7); }
      .can-toggle.rebrand label .can-toggle__switch:after {
        -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.86, 0, 0.07, 1);
        transition: transform 0.3s cubic-bezier(0.86, 0, 0.07, 1);
        color: #444; }
  .can-toggle.rebrand input[type="checkbox"]:focus ~ label .can-toggle__switch:after,
  .can-toggle.rebrand input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4); }
  .can-toggle.rebrand input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
    transform: translate3d(3rem, 0, 0); }
  .can-toggle.rebrand input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle.rebrand input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4); }
  .can-toggle.rebrand label {
    font-size: 1rem; }
    .can-toggle.rebrand label .can-toggle__switch {
      height: 1.875rem;
      flex: 0 0 6.25rem;
      border-radius: 1.875rem; }
      .can-toggle.rebrand label .can-toggle__switch:before {
        left: 3.125rem;
        font-size: 1rem;
        line-height: 1.875rem;
        width: 3.125rem;
        padding: 0 12px; }
      .can-toggle.rebrand label .can-toggle__switch:after {
        top: 0.125rem;
        left: 0.125rem;
        border-radius: 0.9375rem;
        width: 3rem;
        line-height: 1.625rem;
        font-size: 1rem; }
      .can-toggle.rebrand label .can-toggle__switch:hover:after {
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4); }
  .can-toggle.rebrand-two input[type="checkbox"][disabled] ~ label {
    color: rgba(68, 68, 68, 0.5); }
  .can-toggle.rebrand-two input[type="checkbox"]:focus ~ label .can-toggle__switch,
  .can-toggle.rebrand-two input[type="checkbox"]:hover ~ label .can-toggle__switch {
    background-color: #444; }
    .can-toggle.rebrand-two input[type="checkbox"]:focus ~ label .can-toggle__switch:after,
    .can-toggle.rebrand-two input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
      color: #2b2b2b; }
  .can-toggle.rebrand-two input[type="checkbox"]:hover ~ label {
    color: #373737; }
  .can-toggle.rebrand-two input[type="checkbox"]:checked ~ label:hover {
    color: #62b125; }
  .can-toggle.rebrand-two input[type="checkbox"]:checked ~ label .can-toggle__switch {
    background-color: #75d32d; }
    .can-toggle.rebrand-two input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
      color: #5da924; }
  .can-toggle.rebrand-two input[type="checkbox"]:checked:focus ~ label .can-toggle__switch, .can-toggle.rebrand-two input[type="checkbox"]:checked:hover ~ label .can-toggle__switch {
    background-color: #69be28; }
    .can-toggle.rebrand-two input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle.rebrand-two input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {
      color: #52941f; }
  .can-toggle.rebrand-two label {
    flex-wrap: wrap; }
    .can-toggle.rebrand-two label .can-toggle__label-text {
      flex-shrink: 0;
      flex-grow: 0;
      white-space: nowrap;
      margin-right: .5rem; }
    .can-toggle.rebrand-two label .can-toggle__switch {
      transition: background-color 0.3s cubic-bezier(0.86, 0, 0.07, 1);
      background: #515151; }
      .can-toggle.rebrand-two label .can-toggle__switch:before {
        color: rgba(255, 255, 255, 0.7); }
      .can-toggle.rebrand-two label .can-toggle__switch:after {
        -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.86, 0, 0.07, 1);
        transition: transform 0.3s cubic-bezier(0.86, 0, 0.07, 1);
        color: #444; }
  .can-toggle.rebrand-two input[type="checkbox"]:focus ~ label .can-toggle__switch:after,
  .can-toggle.rebrand-two input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4); }
  .can-toggle.rebrand-two input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
    transform: translate3d(3.375rem, 0, 0); }
  .can-toggle.rebrand-two input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle.rebrand-two input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4); }
  .can-toggle.rebrand-two label {
    font-size: 1rem; }
    .can-toggle.rebrand-two label .can-toggle__switch {
      height: 1.875rem;
      flex: 0 0 7rem;
      border-radius: 1.875rem; }
      .can-toggle.rebrand-two label .can-toggle__switch:before {
        left: 3.5rem;
        font-size: 1rem;
        line-height: 1.875rem;
        width: 3.5rem;
        padding: 0 12px; }
      .can-toggle.rebrand-two label .can-toggle__switch:after {
        top: 0.125rem;
        left: 0.125rem;
        border-radius: 0.9375rem;
        width: 3.375rem;
        line-height: 1.625rem;
        font-size: 1rem; }
      .can-toggle.rebrand-two label .can-toggle__switch:hover:after {
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4); }
  .can-toggle.rebrand-three input[type="checkbox"][disabled] ~ label {
    color: rgba(68, 68, 68, 0.5); }
  .can-toggle.rebrand-three input[type="checkbox"]:focus ~ label .can-toggle__switch,
  .can-toggle.rebrand-three input[type="checkbox"]:hover ~ label .can-toggle__switch {
    background-color: #444; }
    .can-toggle.rebrand-three input[type="checkbox"]:focus ~ label .can-toggle__switch:after,
    .can-toggle.rebrand-three input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
      color: #2b2b2b; }
  .can-toggle.rebrand-three input[type="checkbox"]:hover ~ label {
    color: #373737; }
  .can-toggle.rebrand-three input[type="checkbox"]:checked ~ label:hover {
    color: #62b125; }
  .can-toggle.rebrand-three input[type="checkbox"]:checked ~ label .can-toggle__switch {
    background-color: #75d32d; }
    .can-toggle.rebrand-three input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
      color: #5da924; }
  .can-toggle.rebrand-three input[type="checkbox"]:checked:focus ~ label .can-toggle__switch, .can-toggle.rebrand-three input[type="checkbox"]:checked:hover ~ label .can-toggle__switch {
    background-color: #69be28; }
    .can-toggle.rebrand-three input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle.rebrand-three input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {
      color: #52941f; }
  .can-toggle.rebrand-three label {
    flex-wrap: wrap; }
    .can-toggle.rebrand-three label .can-toggle__label-text {
      flex-shrink: 0;
      flex-grow: 0;
      white-space: nowrap;
      margin-right: .5rem; }
    .can-toggle.rebrand-three label .can-toggle__switch {
      transition: background-color 0.3s cubic-bezier(0.86, 0, 0.07, 1);
      background: #515151; }
      .can-toggle.rebrand-three label .can-toggle__switch:before {
        color: rgba(255, 255, 255, 0.7); }
      .can-toggle.rebrand-three label .can-toggle__switch:after {
        -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.86, 0, 0.07, 1);
        transition: transform 0.3s cubic-bezier(0.86, 0, 0.07, 1);
        color: #444; }
  .can-toggle.rebrand-three input[type="checkbox"]:focus ~ label .can-toggle__switch:after,
  .can-toggle.rebrand-three input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4); }
  .can-toggle.rebrand-three input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
    transform: translate3d(4.375rem, 0, 0); }
  .can-toggle.rebrand-three input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle.rebrand-three input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4); }
  .can-toggle.rebrand-three label {
    font-size: 1rem; }
    .can-toggle.rebrand-three label .can-toggle__switch {
      height: 1.875rem;
      flex: 0 0 9rem;
      border-radius: 1.875rem; }
      .can-toggle.rebrand-three label .can-toggle__switch:before {
        left: 4.5rem;
        font-size: 1rem;
        line-height: 1.875rem;
        width: 4.5rem;
        padding: 0 12px; }
      .can-toggle.rebrand-three label .can-toggle__switch:after {
        top: 0.125rem;
        left: 0.125rem;
        border-radius: 0.9375rem;
        width: 4.375rem;
        line-height: 1.625rem;
        font-size: 1rem; }
      .can-toggle.rebrand-three label .can-toggle__switch:hover:after {
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4); }
  .can-toggle.rebrand-three-color input[type="checkbox"][disabled] ~ label {
    color: rgba(19, 132, 150, 0.5); }
  .can-toggle.rebrand-three-color input[type="checkbox"]:focus ~ label .can-toggle__switch,
  .can-toggle.rebrand-three-color input[type="checkbox"]:hover ~ label .can-toggle__switch {
    background-color: #138496; }
    .can-toggle.rebrand-three-color input[type="checkbox"]:focus ~ label .can-toggle__switch:after,
    .can-toggle.rebrand-three-color input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
      color: #0d5c69; }
  .can-toggle.rebrand-three-color input[type="checkbox"]:hover ~ label {
    color: #10707f; }
  .can-toggle.rebrand-three-color input[type="checkbox"]:checked ~ label:hover {
    color: #62b125; }
  .can-toggle.rebrand-three-color input[type="checkbox"]:checked ~ label .can-toggle__switch {
    background-color: #75d32d; }
    .can-toggle.rebrand-three-color input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
      color: #5da924; }
  .can-toggle.rebrand-three-color input[type="checkbox"]:checked:focus ~ label .can-toggle__switch, .can-toggle.rebrand-three-color input[type="checkbox"]:checked:hover ~ label .can-toggle__switch {
    background-color: #69be28; }
    .can-toggle.rebrand-three-color input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle.rebrand-three-color input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {
      color: #52941f; }
  .can-toggle.rebrand-three-color label {
    flex-wrap: wrap; }
    .can-toggle.rebrand-three-color label .can-toggle__label-text {
      flex-shrink: 0;
      flex-grow: 0;
      white-space: nowrap;
      margin-right: .5rem; }
    .can-toggle.rebrand-three-color label .can-toggle__switch {
      transition: background-color 0.3s cubic-bezier(0.86, 0, 0.07, 1);
      background: #1698ad; }
      .can-toggle.rebrand-three-color label .can-toggle__switch:before {
        color: rgba(255, 255, 255, 0.7); }
      .can-toggle.rebrand-three-color label .can-toggle__switch:after {
        -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.86, 0, 0.07, 1);
        transition: transform 0.3s cubic-bezier(0.86, 0, 0.07, 1);
        color: #138496; }
  .can-toggle.rebrand-three-color input[type="checkbox"]:focus ~ label .can-toggle__switch:after,
  .can-toggle.rebrand-three-color input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4); }
  .can-toggle.rebrand-three-color input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
    transform: translate3d(4.375rem, 0, 0); }
  .can-toggle.rebrand-three-color input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle.rebrand-three-color input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4); }
  .can-toggle.rebrand-three-color label {
    font-size: 1rem; }
    .can-toggle.rebrand-three-color label .can-toggle__switch {
      height: 1.875rem;
      flex: 0 0 9rem;
      border-radius: 1.875rem; }
      .can-toggle.rebrand-three-color label .can-toggle__switch:before {
        left: 4.5rem;
        font-size: 1rem;
        line-height: 1.875rem;
        width: 4.5rem;
        padding: 0 12px; }
      .can-toggle.rebrand-three-color label .can-toggle__switch:after {
        top: 0.125rem;
        left: 0.125rem;
        border-radius: 0.9375rem;
        width: 4.375rem;
        line-height: 1.625rem;
        font-size: 1rem; }
      .can-toggle.rebrand-three-color label .can-toggle__switch:hover:after {
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4); }
  .can-toggle.rebrand-four-color input[type="checkbox"][disabled] ~ label {
    color: rgba(19, 132, 150, 0.5); }
  .can-toggle.rebrand-four-color input[type="checkbox"]:focus ~ label .can-toggle__switch,
  .can-toggle.rebrand-four-color input[type="checkbox"]:hover ~ label .can-toggle__switch {
    background-color: #138496; }
    .can-toggle.rebrand-four-color input[type="checkbox"]:focus ~ label .can-toggle__switch:after,
    .can-toggle.rebrand-four-color input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
      color: #0d5c69; }
  .can-toggle.rebrand-four-color input[type="checkbox"]:hover ~ label {
    color: #10707f; }
  .can-toggle.rebrand-four-color input[type="checkbox"]:checked ~ label:hover {
    color: #62b125; }
  .can-toggle.rebrand-four-color input[type="checkbox"]:checked ~ label .can-toggle__switch {
    background-color: #75d32d; }
    .can-toggle.rebrand-four-color input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
      color: #5da924; }
  .can-toggle.rebrand-four-color input[type="checkbox"]:checked:focus ~ label .can-toggle__switch, .can-toggle.rebrand-four-color input[type="checkbox"]:checked:hover ~ label .can-toggle__switch {
    background-color: #69be28; }
    .can-toggle.rebrand-four-color input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle.rebrand-four-color input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {
      color: #52941f; }
  .can-toggle.rebrand-four-color label {
    flex-wrap: wrap; }
    .can-toggle.rebrand-four-color label .can-toggle__label-text {
      flex-shrink: 0;
      flex-grow: 0;
      white-space: nowrap;
      margin-right: .5rem; }
    .can-toggle.rebrand-four-color label .can-toggle__switch {
      transition: background-color 0.3s cubic-bezier(0.86, 0, 0.07, 1);
      background: #1698ad; }
      .can-toggle.rebrand-four-color label .can-toggle__switch:before {
        color: rgba(255, 255, 255, 0.7); }
      .can-toggle.rebrand-four-color label .can-toggle__switch:after {
        -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.86, 0, 0.07, 1);
        transition: transform 0.3s cubic-bezier(0.86, 0, 0.07, 1);
        color: #138496; }
  .can-toggle.rebrand-four-color input[type="checkbox"]:focus ~ label .can-toggle__switch:after,
  .can-toggle.rebrand-four-color input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4); }
  .can-toggle.rebrand-four-color input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
    transform: translate3d(5.375rem, 0, 0); }
  .can-toggle.rebrand-four-color input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle.rebrand-four-color input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4); }
  .can-toggle.rebrand-four-color label {
    font-size: 1rem; }
    .can-toggle.rebrand-four-color label .can-toggle__switch {
      height: 1.875rem;
      flex: 0 0 11rem;
      border-radius: 1.875rem; }
      .can-toggle.rebrand-four-color label .can-toggle__switch:before {
        left: 5.5rem;
        font-size: 1rem;
        line-height: 1.875rem;
        width: 5.5rem;
        padding: 0 12px; }
      .can-toggle.rebrand-four-color label .can-toggle__switch:after {
        top: 0.125rem;
        left: 0.125rem;
        border-radius: 0.9375rem;
        width: 5.375rem;
        line-height: 1.625rem;
        font-size: 1rem; }
      .can-toggle.rebrand-four-color label .can-toggle__switch:hover:after {
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4); }

.gallery {
  display: flex;
  flex-flow: row wrap;
  justify-content: center; }

.gallery-tile {
  margin-bottom: 1em;
  overflow: hidden;
  position: relative;
  cursor: pointer; }
  .gallery-tile img {
    width: 100%;
    height: 100%;
    transition: transform 300ms ease-in-out, filter 300ms ease-in-out; }
  .gallery-tile .picture-info {
    position: absolute;
    z-index: 1;
    color: #ffffff;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
    opacity: 0;
    transition: opacity 300ms ease-in-out, filter 300ms ease-in-out; }
    .gallery-tile .picture-info > * {
      margin: 0.5rem 1.25rem; }
  .gallery-tile:hover img {
    transform: scale(1.1);
    filter: brightness(80%); }
  .gallery-tile:hover .picture-info {
    opacity: 1; }

.imageview-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; }

.imageview {
  display: flex;
  justify-content: center; }

.imageview > * {
  margin: 3em; }

.imageview-image {
  width: 25em;
  height: 25em;
  box-shadow: 0 20px 40px -5px rgba(66, 66, 66, 0.7); }

.imageview-info {
  max-width: 260px;
  text-align: right;
  position: relative; }
  .imageview-info button {
    font-size: 1.5rem;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: #e74c3c;
    cursor: pointer;
    border: none;
    background: none;
    outline: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: -2em;
    right: -1em; }
    .imageview-info button:hover {
      animation: swell 300ms ease-in-out; }
  .imageview-info h2 {
    font-size: 2.5rem;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin-top: 0; }
  .imageview-info h3 {
    font-size: 1.5rem;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.1em; }
  .imageview-info ul {
    list-style: none; }

.fadeIn {
  animation: fade 300ms ease-in-out forwards; }

@keyframes fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes swell {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.3); }
  100% {
    transform: scale(1); } }

/**
* * COMMON COLOR
*/
[class*="sidebar-dark-"] .sidebar a {
  color: #eeeeee; }

[class*="sidebar-dark-"] .brand-link,
[class*="sidebar-dark-"] .brand-link .pushmenu {
  color: white; }

[class*="sidebar-dark-"] .nav-sidebar > .nav-item > .nav-treeview {
  background-color: #414141; }

.main-footer {
  background-color: #fff; }

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--sd-primary); }

.nav-tabs .nav-link {
  background-color: #efefef;
  color: #9b9b9b;
  font-weight: 600; }

[class*="icheck-"] > input:first-child + input[type="hidden"] + label::before,
[class*="icheck-"] > input:first-child + label::before {
  border-radius: 0.25rem; }

.content-wrapper {
  background-color: #fff; }

.content-header {
  color: #323e48; }

/**
* * COLOR - TABLE
*/
.table {
  background-color: white; }
  .table thead {
    background-color: #6b6a68;
    color: white; }

table.dataTable.table-striped > tbody {
  color: #3e4758; }
  table.dataTable.table-striped > tbody > tr.odd > * {
    box-shadow: none;
    background-color: #f6f4ef; }

/**
* * COLOR - LIST TABLE
* ul 組成的 2 欄 list 如 table 般對齊
* 非顏色請往下找 LIST TABLE
*/
.list_table.label_gray li span:first-child {
  color: #9095a0ff; }

.list_table.label_gray li span:last-child {
  color: #171a1fff; }

.list_table.label_primary li span:first-child {
  color: var(--sd-primary); }

.list_table.label_primary li span:last-child {
  color: #171a1fff; }

/**
* * COLOR - LIST INITIAL
* ul 組成的 list
* 非顏色請往下找 LIST INITIAL
*/
.list_initial.label_gray li div:first-child {
  color: #9095a0ff; }

.list_initial.label_gray li div:last-child {
  color: #171a1fff; }

.list_initial.label_primary li div:first-child {
  color: var(--sd-primary); }

.list_initial.label_primary li div:last-child {
  color: #171a1fff; }

/**
* * === NO COLOR ===
*/
/**
* * SIDEBAR
*/
.sidebar {
  padding-bottom: 1.25rem; }

.brand-link .brand-image {
  max-height: 44px;
  margin-left: 0.25rem; }

[class*="sidebar-dark"] .brand-link {
  border-bottom: 0; }

[class*="sidebar-dark"] .nav-sidebar > .nav-item > .nav-link.active {
  -webkit-box-shadow: none;
  box-shadow: none; }

.nav-sidebar .nav-link p {
  white-space: pre; }

/**
* * MARQUEE
*/
.marquee {
  overflow: hidden;
  padding: 0.25rem 0;
  border-radius: 0.25rem; }
  .marquee ul {
    margin: 0;
    display: flex;
    list-style-type: none; }
  .marquee li {
    margin-right: 2rem;
    font-size: 0.875rem; }
  .marquee a,
  .marquee .btn-link {
    font-size: 0.875rem;
    font-weight: 600;
    padding: 0;
    border: 0;
    display: inline-block; }
  .marquee a:hover,
  .marquee .btn-link:hover {
    text-decoration: underline; }

.btn-link {
  border: 0;
  padding: 0;
  display: inline-block; }

.nav-tabs {
  padding-bottom: 0.25rem;
  border-bottom: 0; }
  .nav-tabs .nav-item.show .nav-link i,
  .nav-tabs .nav-link.active i {
    display: inline-block; }
  .nav-tabs .nav-link {
    border-bottom: 1px solid #dee2e6; }
    .nav-tabs .nav-link i {
      display: none; }
  .nav-tabs .nav-item {
    flex-shrink: 0; }

/**
* * NAV-TAB
*/
.tab-content {
  margin-top: -0.25rem;
  border-top: 1px solid #dee2e6; }
  .tab-content > .active {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }

.home_carousel_section {
  height: 20rem; }
  @media (max-width: 576px) {
    .home_carousel_section {
      height: 40rem; } }
  .home_carousel_section .carousel,
  .home_carousel_section .carousel-inner,
  .home_carousel_section .carousel-item {
    height: 100%; }
  .home_carousel_section .carousel-item a {
    display: block;
    height: 100%; }
    .home_carousel_section .carousel-item a img {
      height: 100%;
      object-fit: cover; }

.bgImg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

/**
* * ROW
*/
.form_sm_label > .row {
  margin-bottom: 1.5rem; }
  .form_sm_label > .row:last-child {
    margin-bottom: 0; }

.row {
  margin: 0; }

/**
* * MODAL
*/
.modal-header {
  border-bottom: 0; }

.modal-title {
  font-weight: bold;
  font-size: 1.5rem; }

.modal-body {
  padding-top: 0;
  padding-bottom: 0; }

.modal-footer {
  border-top: 0; }
  .modal-footer button {
    min-width: 6rem;
    line-height: 1; }

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2); }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-xl_all {
  max-width: 1140px; }

/**
* * FORM
*/
form {
  width: 100%; }

.form_sm_label label {
  font-size: 0.8rem;
  color: #999;
  margin-bottom: 0.3rem; }

.form_sm_label.label {
  font-size: 0.8rem;
  color: #999;
  margin-bottom: 0.3rem;
  font-weight: 700; }

.form-control:not(.initial):disabled {
  background-color: white;
  border: 0;
  border-radius: 0;
  padding: 0;
  font-weight: 600;
  font-size: large; }

.form_section {
  border-radius: 4px;
  border: 1px solid #b9aa95;
  padding: 8px; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }

.btn-check + label {
  color: #5f6368;
  border: 1px solid #dadce0; }

.btn-check-primary {
  --bs-btn-active-color: var(--sd-primary);
  --bs-btn-active-bg: var(--sd-primary-slate);
  margin-bottom: 0;
  border: 1px solid var(--sd-primary); }

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg); }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }

.btn-group > .btn:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

/**
* * ICHECK
*/
[class*="icheck-"].icheck-sm > input:first-child + input[type="hidden"] + label::before,
[class*="icheck-"].icheck-sm > input:first-child + label::before {
  width: 1rem;
  height: 1rem;
  margin-left: -1.25rem;
  margin-top: 0.25rem; }

[class*="icheck-"].icheck-sm > input:first-child:checked + input[type="hidden"] + label::after,
[class*="icheck-"].icheck-sm > input:first-child:checked + label::after {
  left: 0.35rem;
  top: 0.05rem; }

/**
* * MAIN BAR
*/
.themebox {
  width: 3rem;
  height: 2rem;
  border: 1px solid #fff;
  position: relative; }
  .themebox[data-theme="default"] {
    background-color: #3e4758; }
  .themebox[data-theme="merino"] {
    background-color: #f0f0e8; }
  .themebox[data-theme="greygoose"] {
    background-color: #cacfd5; }
  .themebox.active:after, .themebox:hover:after {
    color: var(--sd-primary);
    font-family: "FontAwesome";
    position: absolute;
    top: 0.25rem;
    left: 1rem;
    content: "\f00c"; }

/**
* * CONTENT
*/
.content-wrapper > .content {
  padding: 0 2.5rem; }
  @media (max-width: 576px) {
    .content-wrapper > .content {
      padding: 0 1rem; } }

.content-header {
  padding: 0.5rem 1rem;
  padding-left: 0.53rem; }
  .content-header h1 {
    line-height: 1;
    font-size: 1.5rem;
    font-weight: 600; }

/**
* * SEARCH BOX
*/
.search_box {
  border-radius: 0.25rem;
  border: 1px solid #fff;
  background: #fff;
  box-shadow: 0px 0px 20px 5px rgba(195, 205, 213, 0.5);
  padding: 1rem; }
  .search_box > .btn {
    min-width: 8.25rem;
    line-height: 1;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }

@media (max-width: 576px) {
  .btn .btn-word {
    display: none; } }

/**
* * TABLE
*/
div.dataTables_scrollBody {
  border-left: none !important; }

.table.table-striped td,
.table.table-striped th {
  border-top: none; }

.table .pagination {
  margin: 0.5rem; }

thead {
  line-height: 1; }

.table td {
  padding: 0.4rem;
  vertical-align: middle; }

div.dataTables_wrapper div.dataTables_info {
  padding-top: 0; }

div.dataTables_wrapper div.dataTables_paginate {
  margin-right: 1rem; }

@media screen and (max-width: 767px) {
  li.paginate_button.previous {
    display: inline;
    border-left: 1px solid #dee2e6; }
  li.paginate_button.next {
    display: inline; }
  li.paginate_button {
    display: none; }
  .dataTables_info {
    display: none; } }

.table-responsive .bottom {
  margin-top: 0.5rem;
  margin-bottom: 1rem; }

.table_btn {
  font-size: 1.5rem; }

/**
* * BREADCRUMB
*/
.breadcrumb {
  font-size: 0.875rem; }

/**
* * BADGE
*/
.badge {
  border-radius: 1.25rem;
  padding: 0.25rem;
  min-width: 5rem;
  font-size: 1rem;
  font-weight: 400; }

/**
* * ECARD
*/
.ecard_default {
  container-type: inline-size;
  line-height: 1; }
  .ecard_default .name {
    position: fixed;
    font-size: 5.2cqw;
    left: 10.25cqw;
    top: 16.77762cqw; }
    @media (max-width: 576px) {
      .ecard_default .name {
        left: 12cqw; } }
    @media (min-width: 576px) {
      .ecard_default .name {
        left: 11cqw; } }
  .ecard_default .phone {
    position: fixed;
    font-size: 2.13cqw;
    left: 14.24cqw; }
    @media (max-width: 576px) {
      .ecard_default .phone {
        left: 16cqw; } }
    @media (min-width: 576px) {
      .ecard_default .phone {
        left: 15cqw; } }
    .ecard_default .phone.cell {
      top: 28.761651cqw; }
    .ecard_default .phone.tel {
      top: 33.155792cqw; }
  .ecard_default .place {
    position: fixed;
    font-size: 2.4cqw;
    left: 14.24cqw; }
    @media (max-width: 576px) {
      .ecard_default .place {
        left: 16cqw; } }
    @media (min-width: 576px) {
      .ecard_default .place {
        left: 15cqw; } }
    .ecard_default .place.address {
      top: 37.41677762cqw; }
    .ecard_default .place.spotname {
      top: 41.810918774cqw; }

.ecard_picture {
  container-type: inline-size;
  line-height: 1; }
  .ecard_picture .name {
    position: fixed;
    font-size: 5.2cqw;
    left: 43cqw;
    top: 11.984cqw; }
    @media (min-width: 576px) {
      .ecard_picture .name {
        left: 42cqw; } }
    @media (min-width: 992px) {
      .ecard_picture .name {
        left: 41.677762cqw; } }
  .ecard_picture .phone {
    position: fixed;
    font-size: 2.13cqw;
    left: 48cqw; }
    @media (min-width: 576px) {
      .ecard_picture .phone {
        left: 47cqw; } }
    @media (min-width: 992px) {
      .ecard_picture .phone {
        left: 46cqw; } }
    .ecard_picture .phone.cell {
      top: 19.30758988cqw; }
    .ecard_picture .phone.tel {
      top: 23.5685752cqw; }
  .ecard_picture .place {
    position: fixed;
    font-size: 2.4cqw;
    left: 48cqw; }
    @media (min-width: 576px) {
      .ecard_picture .place {
        left: 47cqw; } }
    @media (min-width: 992px) {
      .ecard_picture .place {
        left: 46cqw; } }
    .ecard_picture .place.address {
      top: 27.4300932cqw; }
    .ecard_picture .place.spotname {
      top: 31.55792cqw; }
  .ecard_picture .photo {
    position: fixed;
    width: 22cqw;
    height: 27cqw;
    left: 9.5cqw;
    top: 10.4cqw;
    object-fit: cover; }
    @media (min-width: 576px) {
      .ecard_picture .photo {
        left: 8.6cqw; } }
    @media (min-width: 992px) {
      .ecard_picture .photo {
        left: 8.32cqw; } }

/**
* * IMGLOOP
*/
.imgloop {
  background-color: var(--sd-success-opacity) !important;
  padding: 0.25rem !important;
  margin-bottom: 0.25rem !important;
  align-items: center !important;
  justify-content: space-between !important;
  display: flex !important;
  border-radius: 0.25rem !important; }

/**
* * SPLIDE
*/
.splide__slide img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.splide__slide {
  opacity: 0.3; }

.splide__slide.is-active {
  opacity: 1; }

.splide__track--nav > .splide__list > .splide__slide.is-active {
  border: 0; }

/**
* * LIST TABLE
* ul 組成的 2 欄 list 如 table 般對齊
* 顏色請往上找 COLOR - LIST TABLE
*/
.list_table li {
  margin-bottom: 0.25rem;
  display: flex; }
  .list_table li span:first-child {
    flex-shrink: 0; }
  .list_table li span:last-child {
    flex: 1 1 auto !important; }

.list_table li:nth-of-type(odd) {
  padding-right: 3.3125rem; }

.list_table li:nth-of-type(even) {
  padding-right: 3.3125rem; }

.list_table.basis_128px li span:first-child {
  flex-basis: 7.8125rem; }

.list_table.basis_60px li span:first-child {
  flex-basis: 3.75rem; }

.list_table.li_fs_14px li {
  font-size: 0.875rem; }

.list_table.li_pr_14px li {
  padding-right: 0.875rem; }

.list_table.li_pr_14px li:nth-of-type(3n) {
  padding-right: 0; }

.list_table.li_mb_6px li {
  margin-bottom: 0.375rem; }

.list_table.li_span-f_mr-3 span:first-child {
  margin-right: 1rem; }

.list_table.li_mn_h_42px li {
  min-height: 2.625rem; }

/**
* * LIST INITIAL
* ul 組成的 list
* 非顏色請往下找 LIST INITIAL
*/
.list_initial li {
  font-size: 0.875rem;
  margin-bottom: 0.25rem; }

.list-unstyled {
  margin-bottom: 0; }

/**
* * FLATPICKR
*/
.flatpickr input {
  border: 0; }

/**
* *FULLCALENDAR
*/
.fc-daygrid-dot-event .fc-event-title {
  font-weight: normal; }

.fc .fc-daygrid-more-link {
  font-weight: bolder; }

/**
* DIRECT CHAT
*/
.direct-chat-text {
  background-color: var(--sd-primary-slate); }

.direct-chat-text::after,
.direct-chat-text::before {
  border-right-color: var(--sd-primary-slate); }

/**
* *SUMMERNOTE
*/
.note-modal .modal-header {
  flex-direction: row-reverse; }

/**
* * UTILITY
*/
.fs_12px {
  font-size: 0.75rem !important; }

.fs_14px {
  font-size: 0.875rem !important; }

.fs_18px {
  font-size: 1.125rem !important; }

.fs_22px {
  font-size: 1.375rem !important; }

.fs_24px {
  font-size: 1.5rem !important; }

.fs_36px {
  font-size: 2.25rem !important; }

.fs_40px {
  font-size: 2.5rem !important; }

.fs_48px {
  font-size: 3rem !important; }

.fs_rwd_24px {
  font-size: 1.5rem !important; }
  @media (min-width: 576px) {
    .fs_rwd_24px {
      font-size: 1rem !important; } }

.mn_w_144px {
  min-width: 9rem !important; }

.mx_w_50per {
  max-width: 50% !important; }

@media (min-width: 992px) {
  .h-lg-100 {
    height: 100% !important; } }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.ml_6 {
  margin-left: 4.8rem !important; }

.mb_xs {
  margin-bottom: 0.18rem !important; }

.mb_6px {
  margin-bottom: 0.375rem !important; }

.mb_20px {
  margin-bottom: 1.2rem !important; }

.mb_22px {
  margin-bottom: 1.375rem !important; }

.mb_26px {
  margin-bottom: 1.625rem !important; }

.mb_34px {
  margin-bottom: 2.125rem !important; }

.mb_40px {
  margin-bottom: 2.5rem !important; }

.mr_rwd_2 {
  margin-right: 0.5rem !important; }
  @media (max-width: 576px) {
    .mr_rwd_2 {
      margin-right: 0 !important; } }

.ml_rwd_2 {
  margin-left: 0.5rem !important; }
  @media (max-width: 576px) {
    .ml_rwd_2 {
      margin-left: 0 !important; } }

.ml_rwd_3 {
  margin-left: 1rem !important; }
  @media (max-width: 576px) {
    .ml_rwd_3 {
      margin-left: 0 !important; } }

.pb_45px {
  padding-bottom: 2.8125rem; }

.overflow-x-auto {
  overflow-x: auto !important; }

.overflow-y-auto {
  overflow-y: auto !important; }

.bg-warning-slate {
  background-color: rgba(255, 193, 7, 0.25) !important; }

.text_rwd_nowrap {
  white-space: nowrap !important; }
  @media (max-width: 576px) {
    .text_rwd_nowrap {
      white-space: normal !important; } }

.border_left_20px {
  border-left: 20px solid transparent; }

.border_left_rwd_40px {
  border-left: 40px solid transparent; }
  @media (max-width: 576px) {
    .border_left_rwd_40px {
      border-left: 0 !important; } }

.list_center li {
  text-align: center; }

.list_flush li {
  border: 1px solid #dee1e6ff;
  margin-bottom: 1rem; }

.list_flush.border_w3px li {
  --border-wide: 3px; }

.list_flush.right li {
  border-width: 0 var(--border-wide) 0 0; }
  @media (max-width: 576px) {
    .list_flush.right li {
      border-width: 0; } }

@media (min-width: 576px) {
  .list_flush.right li:nth-child(even) {
    border-width: 0; } }

@media (min-width: 768px) {
  .list_flush.right li:nth-child(even) {
    border-width: 0 var(--border-wide) 0 0; } }

.list_flush.right li:last-child {
  border-right-width: 0; }

.bottom-0 {
  bottom: 0; }
