//
// Mixins: Button
//
// Button Variant
@mixin button-variant($name) {
    color: #fff;
    background-color: var(--sd-#{$name});
    border-color: var(--sd-#{$name});
    box-shadow: none;
    &:hover {
        color: #fff;
        background-color: var(--sd-#{$name}-hover);
        border-color: var(--sd-#{$name}-hover);
        filter: drop-shadow(5px 5px 10px var(--sd-#{$name}-shadow));
    }
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    .show > &.dropdown-toggle {
        color: #fff;
        background-color: var(--sd-#{$name}-hover);
        border-color: var(--sd-#{$name}-hover);
    }
}
@mixin button-outline-variant($name) {
    color: var(--sd-#{$name});
    border-color: var(--sd-#{$name});
    &:hover {
        color: #fff;
        background-color: var(--sd-#{$name});
        border-color: var(--sd-#{$name});
        filter: drop-shadow(5px 5px 10px var(--sd-#{$name}-shadow));
    }
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    .show > &.dropdown-toggle {
        color: #fff;
        background-color: var(--sd-#{$name}-hover);
        border-color: var(--sd-#{$name}-hover);
    }
}
