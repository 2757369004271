@import "shending-variables";
@import "btn-color";
@import "theme";
@import "loading";
@import "checkbox-switch";
@import "gallery";

@mixin mobile {
  @media (max-width: 576px) {
    @content;
  }
}
@mixin landscapePhone {
  @media (min-width: 576px) {
    @content;
  }
}
@mixin tablets {
  @media (min-width: 768px) {
    @content;
  }
}
@mixin desktops {
  @media (min-width: 992px) {
    @content;
  }
}

// font-family: "MRazor HK Xbold";
// <link href="https://db.onlinewebfonts.com/c/97214b891985329956fb70b2337a54d4?family=MRazor+HK+Xbold" rel="stylesheet">
// @font-face {
//   font-family: "MRazor HK Xbold";
//   src: url("https://db.onlinewebfonts.com/t/97214b891985329956fb70b2337a54d4.eot");
//   src: url("https://db.onlinewebfonts.com/t/97214b891985329956fb70b2337a54d4.eot?#iefix")format("embedded-opentype"),
//   url("https://db.onlinewebfonts.com/t/97214b891985329956fb70b2337a54d4.woff2")format("woff2"),
//   url("https://db.onlinewebfonts.com/t/97214b891985329956fb70b2337a54d4.woff")format("woff"),
//   url("https://db.onlinewebfonts.com/t/97214b891985329956fb70b2337a54d4.ttf")format("truetype"),
//   url("https://db.onlinewebfonts.com/t/97214b891985329956fb70b2337a54d4.svg#MRazor HK Xbold")format("svg");
// }

/**
* * COMMON COLOR
*/
[class*="sidebar-dark-"] {
  .sidebar a {
    color: #eeeeee;
  }
  .brand-link,
  .brand-link .pushmenu {
    color: rgba(255, 255, 255, 1);
  }
  .nav-sidebar > .nav-item > .nav-treeview {
    background-color: #414141;
  }
}
.main-footer {
  background-color: #fff;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--sd-primary);
}

.nav-tabs .nav-link {
  background-color: #efefef;
  color: #9b9b9b;
  font-weight: 600;
}

[class*="icheck-"] > input:first-child + input[type="hidden"] + label::before,
[class*="icheck-"] > input:first-child + label::before {
  border-radius: 0.25rem;
}
.content-wrapper {
  background-color: #fff;
}
.content-header {
  color: #323e48;
}

/**
* * COLOR - TABLE
*/
.table {
  background-color: white;
  thead {
    background-color: #6b6a68;
    color: white;
  }
}

table.dataTable.table-striped > tbody {
  color: #3e4758;
  > tr.odd > * {
    box-shadow: none;
    background-color: #f6f4ef;
  }
}

/**
* * COLOR - LIST TABLE
* ul 組成的 2 欄 list 如 table 般對齊
* 非顏色請往下找 LIST TABLE
*/
.list_table {
  &.label_gray li {
    span:first-child {
      color: #9095a0ff;
    }
    span:last-child {
      color: #171a1fff;
    }
  }
  &.label_primary li {
    span:first-child {
      color: var(--sd-primary);
    }
    span:last-child {
      color: #171a1fff;
    }
  }
}
/**
* * COLOR - LIST INITIAL
* ul 組成的 list
* 非顏色請往下找 LIST INITIAL
*/
.list_initial {
  &.label_gray li {
    div:first-child {
      color: #9095a0ff;
    }
    div:last-child {
      color: #171a1fff;
    }
  }
  &.label_primary li {
    div:first-child {
      color: var(--sd-primary);
    }
    div:last-child {
      color: #171a1fff;
    }
  }
}

/**
* * === NO COLOR ===
*/
/**
* * SIDEBAR
*/
.sidebar {
  padding-bottom: 1.25rem;
}
.brand-link .brand-image {
  max-height: 44px;
  margin-left: 0.25rem;
}
[class*="sidebar-dark"] {
  .brand-link {
    border-bottom: 0;
  }
  .nav-sidebar > .nav-item > .nav-link.active {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
.nav-sidebar .nav-link p {
  white-space: pre;
}

/**
* * MARQUEE
*/
.marquee {
  overflow: hidden;
  padding: 0.25rem 0;
  border-radius: 0.25rem;
  ul {
    margin: 0;
    display: flex;
    list-style-type: none;
  }
  li {
    margin-right: 2rem;
    font-size: 0.875rem;
  }
  a,
  .btn-link {
    font-size: 0.875rem;
    font-weight: 600;
    padding: 0;
    border: 0;
    display: inline-block;
  }
  a:hover,
  .btn-link:hover {
    text-decoration: underline;
  }
}

.btn-link {
  border: 0;
  padding: 0;
  display: inline-block;
}

.nav-tabs {
  padding-bottom: 0.25rem;
  border-bottom: 0;
  .nav-item.show .nav-link,
  .nav-link.active {
    i {
      display: inline-block;
    }
  }
  .nav-link {
    border-bottom: 1px solid #dee2e6;
    i {
      display: none;
    }
  }
  .nav-item {
    flex-shrink: 0;
  }
}

/**
* * NAV-TAB
*/
.tab-content {
  margin-top: -0.25rem;
  border-top: 1px solid #dee2e6;
  > .active {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

.home_carousel_section {
  height: 20rem;
  @include mobile {
    height: 40rem;
  }

  .carousel,
  .carousel-inner,
  .carousel-item {
    height: 100%;
  }
  .carousel-item a {
    display: block;
    height: 100%;
    img {
      height: 100%;
      object-fit: cover;
    }
  }
}

.bgImg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/**
* * ROW
*/
.form_sm_label > .row {
  margin-bottom: 1.5rem;
  &:last-child {
    margin-bottom: 0;
  }
}
.row {
  margin: 0;
}

/**
* * MODAL
*/
.modal-header {
  border-bottom: 0;
}
.modal-title {
  font-weight: bold;
  font-size: 1.5rem;
}
.modal-body {
  padding-top: 0;
  padding-bottom: 0;
}
.modal-footer {
  border-top: 0;
  button {
    min-width: 6rem;
    line-height: 1;
  }
}
.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
  .modal-content {
    max-height: 100%;
    overflow: hidden;
  }
  .modal-body {
    overflow-y: auto;
  }
}
.modal-xl_all {
  max-width: 1140px;
}

/**
* * FORM
*/
form {
  width: 100%;
}
.form_sm_label {
  label {
    font-size: 0.8rem;
    color: #999;
    margin-bottom: 0.3rem;
  }

  &.label {
    font-size: 0.8rem;
    color: #999;
    margin-bottom: 0.3rem;
    font-weight: 700;
  }
}

.form-control:not(.initial):disabled {
  background-color: white;
  border: 0;
  border-radius: 0;
  padding: 0;
  font-weight: 600;
  font-size: large;
}
.form_section {
  border-radius: 4px;
  border: 1px solid #b9aa95;
  padding: 8px;
}
.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check + label {
  color: #5f6368;
  border: 1px solid #dadce0;
}
.btn-check-primary {
  --bs-btn-active-color: var(--sd-primary);
  --bs-btn-active-bg: var(--sd-primary-slate);
  margin-bottom: 0;
  border: 1px solid var(--sd-primary);
}
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.btn-group > .btn:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

/**
* * ICHECK
*/
[class*="icheck-"].icheck-sm > input:first-child {
  + input[type="hidden"] + label::before,
  + label::before {
    width: 1rem;
    height: 1rem;
    margin-left: -1.25rem;
    margin-top: 0.25rem;
  }
  &:checked + input[type="hidden"] + label::after,
  &:checked + label::after {
    left: 0.35rem;
    top: 0.05rem;
  }
}

/**
* * MAIN BAR
*/
.themebox {
  width: 3rem;
  height: 2rem;
  border: 1px solid #fff;
  position: relative;
  &[data-theme="default"] {
    background-color: #3e4758;
  }
  &[data-theme="merino"] {
    background-color: #f0f0e8;
  }
  &[data-theme="greygoose"] {
    background-color: #cacfd5;
  }
  &.active:after,
  &:hover:after {
    color: var(--sd-primary);
    font-family: "FontAwesome";
    position: absolute;
    top: 0.25rem;
    left: 1rem;
    content: "\f00c";
  }
}

/**
* * CONTENT
*/
.content-wrapper > .content {
  padding: 0 2.5rem;
  @include mobile {
    padding: 0 1rem;
  }
}
// content-h1
.content-header {
  padding: 0.5rem 1rem;
  padding-left: 0.53rem;
  h1 {
    line-height: 1;
    font-size: 1.5rem;
    font-weight: 600;
  }
}

/**
* * SEARCH BOX
*/
.search_box {
  border-radius: 0.25rem;
  border: 1px solid #fff;
  background: #fff;
  box-shadow: 0px 0px 20px 5px rgba(195, 205, 213, 0.5);
  padding: 1rem;
  > .btn {
    min-width: 8.25rem;
    line-height: 1;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

//btn
.btn .btn-word {
  @include mobile {
    display: none;
  }
}

/**
* * TABLE
*/
div.dataTables_scrollBody {
  border-left: none !important;
}
.table.table-striped td,
.table.table-striped th {
  border-top: none;
}
.table .pagination {
  margin: 0.5rem;
}
thead {
  line-height: 1;
}
.table td {
  padding: 0.4rem;
  vertical-align: middle;
}
div.dataTables_wrapper div.dataTables_info {
  padding-top: 0;
}
div.dataTables_wrapper div.dataTables_paginate {
  margin-right: 1rem;
}
@media screen and (max-width: 767px) {
  li.paginate_button.previous {
    display: inline;
    border-left: 1px solid #dee2e6;
  }

  li.paginate_button.next {
    display: inline;
  }

  li.paginate_button {
    display: none;
  }
  .dataTables_info {
    display: none;
  }
}
.table-responsive .bottom {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
.table_btn {
  font-size: 1.5rem;
}
/**
* * BREADCRUMB
*/
.breadcrumb {
  font-size: 0.875rem;
}

/**
* * BADGE
*/
.badge {
  border-radius: 1.25rem;
  padding: 0.25rem;
  min-width: 5rem;
  font-size: 1rem;
  font-weight: 400;
}

/**
* * ECARD
*/
.ecard_default {
  container-type: inline-size;
  line-height: 1;
  .name {
    // font-family: "MRazor HK Xbold";
    position: fixed;
    font-size: 5.2cqw;
    left: 10.25cqw;
    top: 16.77762cqw;
    @include mobile {
      left: 12cqw;
    }
    @include landscapePhone {
      left: 11cqw;
    }
  }
  .phone {
    position: fixed;
    font-size: 2.13cqw;
    left: 14.24cqw;
    @include mobile {
      left: 16cqw;
    }
    @include landscapePhone {
      left: 15cqw;
    }
    &.cell {
      top: 28.761651cqw;
    }
    &.tel {
      top: 33.155792cqw;
    }
  }
  .place {
    position: fixed;
    font-size: 2.4cqw;
    left: 14.24cqw;
    @include mobile {
      left: 16cqw;
    }
    @include landscapePhone {
      left: 15cqw;
    }
    &.address {
      top: 37.41677762cqw;
    }
    &.spotname {
      top: 41.810918774cqw;
    }
  }
}
.ecard_picture {
  container-type: inline-size;
  line-height: 1;
  .name {
    // font-family: "MRazor HK Xbold";
    position: fixed;
    font-size: 5.2cqw;
    left: 43cqw;
    top: 11.984cqw;
    @include landscapePhone {
      left: 42cqw;
    }
    @include desktops {
      left: 41.677762cqw;
    }
  }
  .phone {
    position: fixed;
    font-size: 2.13cqw;
    left: 48cqw;
    @include landscapePhone {
      left: 47cqw;
    }
    @include desktops {
      left: 46cqw;
    }
    &.cell {
      top: 19.30758988cqw;
    }
    &.tel {
      top: 23.5685752cqw;
    }
  }
  .place {
    position: fixed;
    font-size: 2.4cqw;
    left: 48cqw;
    @include landscapePhone {
      left: 47cqw;
    }
    @include desktops {
      left: 46cqw;
    }
    &.address {
      top: 27.4300932cqw;
    }
    &.spotname {
      top: 31.55792cqw;
    }
  }
  .photo {
    position: fixed;
    width: 22cqw;
    height: 27cqw;
    left: 9.5cqw;
    top: 10.4cqw;
    object-fit: cover;
    @include landscapePhone {
      left: 8.6cqw;
    }
    @include desktops {
      left: 8.32cqw;
    }
  }
}

/**
* * IMGLOOP
*/
.imgloop {
  background-color: var(--sd-success-opacity) !important;
  padding: 0.25rem !important;
  margin-bottom: 0.25rem !important;
  align-items: center !important;
  justify-content: space-between !important;
  display: flex !important;
  border-radius: 0.25rem !important;
}

/**
* * SPLIDE
*/
.splide__slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.splide__slide {
  opacity: 0.3;
}

.splide__slide.is-active {
  opacity: 1;
}
.splide__track--nav > .splide__list > .splide__slide.is-active {
  border: 0;
}

/**
* * LIST TABLE
* ul 組成的 2 欄 list 如 table 般對齊
* 顏色請往上找 COLOR - LIST TABLE
*/
.list_table {
  li {
    margin-bottom: 0.25rem;
    display: flex;
    span:first-child {
      flex-shrink: 0;
    }
    span:last-child {
      flex: 1 1 auto !important;
    }
  }
  li:nth-of-type(odd) {
    padding-right: 3.3125rem;
  }
  li:nth-of-type(even) {
    padding-right: 3.3125rem;
  }
  &.basis_128px li {
    span:first-child {
      flex-basis: 7.8125rem;
    }
  }
  &.basis_60px li {
    span:first-child {
      flex-basis: 3.75rem;
    }
  }
  &.li_fs_14px li {
    font-size: 0.875rem;
  }
  &.li_pr_14px {
    li {
      padding-right: 0.875rem;
    }
    li:nth-of-type(3n) {
      padding-right: 0;
    }
  }
  &.li_mb_6px {
    li {
      margin-bottom: 0.375rem;
    }
  }
  &.li_span-f_mr-3 {
    span:first-child {
      margin-right: 1rem;
    }
  }
  &.li_mn_h_42px {
    li {
      min-height: 2.625rem;
    }
  }
}

/**
* * LIST INITIAL
* ul 組成的 list
* 非顏色請往下找 LIST INITIAL
*/
.list_initial {
  li {
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
  }
}

.list-unstyled {
  margin-bottom: 0;
}

/**
* * FLATPICKR
*/
.flatpickr input {
  border: 0;
}

/**
* *FULLCALENDAR
*/
.fc-daygrid-dot-event .fc-event-title {
  font-weight: normal;
}
.fc .fc-daygrid-more-link {
  font-weight: bolder;
}
/**
* DIRECT CHAT
*/
.direct-chat-text {
  background-color: var(--sd-primary-slate);
}
.direct-chat-text::after,
.direct-chat-text::before {
  border-right-color: var(--sd-primary-slate);
}

// -START 20240718-summernote modal-title 調整
/**
* *SUMMERNOTE
*/
.note-modal .modal-header {
  flex-direction: row-reverse;
}
// -END 20240718-summernote modal-title 調整

/**
* * UTILITY
*/
.fs_12px {
  font-size: 0.75rem !important;
}
.fs_14px {
  font-size: 0.875rem !important;
}
.fs_18px {
  font-size: 1.125rem !important;
}
.fs_22px {
  font-size: 1.375rem !important;
}
.fs_24px {
  font-size: 1.5rem !important;
}
.fs_36px {
  font-size: 2.25rem !important;
}
.fs_40px {
  font-size: 2.5rem !important;
}
.fs_48px {
  font-size: 3rem !important;
}
.fs_rwd_24px {
  font-size: 1.5rem !important;
  @include landscapePhone {
    font-size: 1rem !important;
  }
}

.mn_w_144px {
  min-width: 9rem !important;
}
.mx_w_50per {
  max-width: 50% !important;
}
.h-lg-100 {
  @include desktops {
    height: 100% !important;
  }
}

.lh-1 {
  line-height: 1 !important;
}
.lh-sm {
  line-height: 1.25 !important;
}

.ml_6 {
  margin-left: 4.8rem !important;
}

.mb_xs {
  margin-bottom: 0.18rem !important;
}
.mb_6px {
  margin-bottom: 0.375rem !important;
}
.mb_20px {
  margin-bottom: 1.2rem !important;
}
.mb_22px {
  margin-bottom: 1.375rem !important;
}
.mb_26px {
  margin-bottom: 1.625rem !important;
}
.mb_34px {
  margin-bottom: 2.125rem !important;
}
.mb_40px {
  margin-bottom: 2.5rem !important;
}

.mr_rwd_2 {
  margin-right: 0.5rem !important;
  @include mobile {
    margin-right: 0 !important;
  }
}
.ml_rwd_2 {
  margin-left: 0.5rem !important;
  @include mobile {
    margin-left: 0 !important;
  }
}
.ml_rwd_3 {
  margin-left: 1rem !important;
  @include mobile {
    margin-left: 0 !important;
  }
}

.pb_45px {
  padding-bottom: 2.8125rem;
}

.overflow-x-auto {
  overflow-x: auto !important;
}
.overflow-y-auto {
  overflow-y: auto !important;
}

.bg-warning-slate {
  background-color: rgba(255, 193, 7, 0.25) !important;
}

.text_rwd_nowrap {
  white-space: nowrap !important;
  @include mobile {
    white-space: normal !important;
  }
}

.border_left_20px {
  border-left: 20px solid transparent;
}

.border_left_rwd_40px {
  border-left: 40px solid transparent;
  @include mobile {
    border-left: 0 !important;
  }
}

.list_center {
  li {
    text-align: center;
  }
}
.list_flush {
  li {
    border: 1px solid #dee1e6ff;
    margin-bottom: 1rem;
  }
  &.border_w3px li {
    --border-wide: 3px;
  }
  &.right li {
    border-width: 0 var(--border-wide) 0 0;
    @include mobile {
      border-width: 0;
    }
  }
  &.right li:nth-child(even) {
    @include landscapePhone {
      border-width: 0;
    }
    @include tablets() {
      border-width: 0 var(--border-wide) 0 0;
    }
  }
  &.right li:last-child {
    border-right-width: 0;
  }
}

.bottom-0 {
  bottom: 0;
}
