//theme
[class*="sidebar-dark-"] {
    background-color: var(--sd-secondary);
    .nav-sidebar > .nav-item > .nav-link.active {
        color: var(--sd-mark);
    }

    .nav-sidebar > .nav-item.menu-open > .nav-link,
    .nav-sidebar > .nav-item:hover > .nav-link,
    .nav-sidebar > .nav-item > .nav-link:focus {
        background-color: var(--sd-highlight);
        color: var(--sd-mark);
    }

    .nav-treeview > .nav-item {
        > .nav-link.active,
        > .nav-link.active:hover,
        > .nav-link.active:focus {
            background-color: var(--sd-primary);
            color: var(--sd-mark);
        }
        > .nav-link:hover,
        > .nav-link:focus {
            background-color: var(--sd-highlight);
            color: var(--sd-mark);
        }
    }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    background-color: var(--sd-primary);
}

.navbar-light {
    background-color: var(--sd-highlight);
    .navbar-nav {
        .nav-link {
            color: var(--sd-primary);
        }
        .show > .nav-link,
        .active > .nav-link,
        .nav-link.show,
        .nav-link.active {
            color: var(--sd-primary);
        }
    }
}

.navbar-light .navbar-nav .nav-link:hover {
    color: var(--sd-primary-hover);
}

a {
    color: var(--sd-primary);
    &:hover {
        color: var(--sd-primary-hover);
    }
}
.dropdown-item.active,
.dropdown-item:active {
    background-color: var(--sd-primary);
}
//marquee
.marquee {
    background: var(--sd-mark);
    color: var(--sd-highlight);

    a,
    .btn-link {
        color: var(--sd-highlight);
        &:hover{
            color: var(--sd-primary-hover);
        }
    }
}

//theme-modal
.modal-title {
    color: var(--sd-primary);
}
.btn-link {
    color: var(--sd-primary);
    &:hover {
        color: var(--sd-primary-hover);
    }
}
//theme-icheck
.icheck-primary {
    > input:first-child:checked + input[type="hidden"] + label::before,
    > input:first-child:checked + label::before {
        background-color: var(--sd-primary);
        border-color: var(--sd-primary);
        // filter: drop-shadow(5px 5px 10px var(--sd-primary-shadow));
    }

    > input:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before,
    > input:first-child:not(:checked):not(:disabled):hover + label::before {
        border-color: var(--sd-primary);
    }
}

.icheck-success {
    > input:first-child:checked + input[type="hidden"] + label::before,
    > input:first-child:checked + label::before {
        background-color: var(--sd-success);
        border-color: var(--sd-success);
        // filter: drop-shadow(5px 5px 10px var(--sd-success-shadow));
    }

    > input:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before,
    > input:first-child:not(:checked):not(:disabled):hover + label::before {
        border-color: var(--sd-success);
    }
}

//pagination
.page-item.active .page-link {
    color: #fff;
    background-color: var(--sd-primary);
    border-color: var(--sd-primary);
    box-shadow: 0 5px 14px 0 var(--sd-primary-shadow) !important;
}

.page-item .page-link {
    color: var(--sd-primary-hover);
}

//button
.btn-primary {
    @include button-variant("primary");
}
.btn-standard {
    @include button-variant("standard");
}
.btn-success {
    @include button-variant("success");
}
.btn-info {
    @include button-variant("info");
}
.btn-other {
    @include button-variant("other");
}

.btn-outline-primary {
    @include button-outline-variant("primary");
}
.btn-outline-info {
    @include button-outline-variant("info");
}
.btn-outline-other {
    @include button-outline-variant("other");
}

//text
.text-primary {
    color: var(--sd-primary) !important;
}
.text-secondary {
    color: var(--sd-secondary) !important;
}
.text-highlight {
    color: var(--sd-highlight) !important;
}
.text-mark {
    color: var(--sd-mark) !important;
}

.text-standard {
    color: var(--sd-standard) !important;
}
.text-danger {
    color: var(--sd-danger) !important;
}
.text-success {
    color: var(--sd-success) !important;
}
.text-info {
    color: var(--sd-info) !important;
}
.text-other {
    color: var(--sd-other) !important;
}

//background-color
.bg-primary {
    background-color: var(--sd-primary) !important;
}
.bg-secondary {
    background-color: var(--sd-secondary) !important;
}
.bg-highlight {
    background-color: var(--sd-highlight) !important;
}
.bg-mark {
    background-color: var(--sd-mark) !important;
}

.bg-standard {
    background-color: var(--sd-standard) !important;
}
.bg-danger {
    background-color: var(--sd-danger) !important;
}
.bg-success {
    background-color: var(--sd-success) !important;
    &-slate {
        background-color: var(--sd-success-opacity) !important;
    }
}
.bg-info {
    background-color: var(--sd-info) !important;
}
.bg-other {
    background-color: var(--sd-other) !important;
}

//badge
.badge-primary {
    background-color: var(--sd-primary) !important;
}
.badge-secondary {
    background-color: var(--sd-secondary) !important;
}
.badge-highlight {
    background-color: var(--sd-highlight) !important;
}
.badge-mark {
    background-color: var(--sd-mark) !important;
}

.badge-standard {
    background-color: var(--sd-standard) !important;
}
.badge-danger {
    background-color: var(--sd-danger) !important;
}
.badge-success {
    background-color: var(--sd-success) !important;
}
.badge-info {
    background-color: var(--sd-info) !important;
}
.badge-other {
    background-color: var(--sd-other) !important;
}
.badge-disable {
    background-color: var(--sd-disable) !important;
}

//border
.border-primary {
    border-color: var(--sd-primary) !important;
}
.border-secondary {
    border-color: var(--sd-secondary) !important;
}
.border-highlight {
    border-color: var(--sd-highlight) !important;
}
.border-mark {
    border-color: var(--sd-mark) !important;
}

.border-standard {
    border-color: var(--sd-standard) !important;
}
.border-danger {
    border-color: var(--sd-danger) !important;
}
.border-success {
    border-color: var(--sd-success) !important;
}
.border-info {
    border-color: var(--sd-info) !important;
}
.border-other {
    border-color: var(--sd-other) !important;
}
.border-disable {
    border-color: var(--sd-disable) !important;
}
