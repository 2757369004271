.loader {
    display: -ms-flexbox;
    display: flex;
    height: 100vh;
    width: 100%;
    transition: height .2s linear;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
}

.spinner {
    background: transparent;
    position: absolute;
    z-index: 1;
    width: 120px;
    height: 120px;
    margin: -60px 0 0 120px;
    border: 16px solid #444941;
    border-radius: 50%;
    border-top: 16px solid #f8b133;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}