:root {
    --sd-primary: #b56757;
    --sd-secondary: #5f5f5f;
    --sd-highlight: #3e4758;
    --sd-mark: #d7ccb7;
    --sd-neutral: #f5f4ef;

    --sd-primary-slate: #f7f2eb;
    --sd-primary-hover: #cc9983;
    --sd-primary-shadow: rgba(204, 153, 131, 0.3);

    // standard
    --sd-standard: #0081c9;
    --sd-standard-hover: #00669e;
    --sd-standard-shadow: rgb(0, 102, 158, 0.3);
    // danger
    --sd-danger: #df0143;
    --sd-danger-hover: #b30000;
    --sd-danger-shadow: rgb(179, 0, 0, 0.3);
    // success
    --sd-success: #47a992;
    --sd-success-hover: #57c5b6;
    --sd-success-shadow: rgba(87, 197, 182, 0.3);
    --sd-success-opacity: rgba(87, 197, 182, 0.25);
    // info
    --sd-info: #ff7b54;
    --sd-info-hover: #fc501d;
    --sd-info-shadow: rgba(252, 80, 29, 0.3);
    //other
    --sd-other: #37306b;
    --sd-other-hover: #66347f;
    --sd-other-shadow: rgb(102, 52, 127, 0.3);

    //disble
    --sd-disable: #ccc;
}

body.theme-merino {
    --sd-primary: #bca482;
    --sd-secondary: #a56255;
    --sd-highlight: #eae8da;
    --sd-mark: #5c6856;
    --sd-neutral: #eee8c6;

    --sd-primary-slate: #f7f6f0;
    --sd-primary-hover: #d1c1a3;
    --sd-primary-shadow: rgba(209, 193, 163, 0.3);
}

body.theme-greygoose {
    --sd-primary: #4b5958;
    --sd-secondary: #62868c;
    --sd-highlight: #d7e2d5;
    --sd-mark: #859e99;
    --sd-neutral: #d6e4e4;

    --sd-primary-slate: #ebf0f0;
    --sd-primary-hover: #7d8c8b;
    --sd-primary-shadow: rgba(125, 140, 139, 0.3);
}
